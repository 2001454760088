import { defineStore } from 'pinia'

interface NumberOfCompanies {
  companyNumber: number
}

interface NumberOfAccounts {
  accountNumber: number
}

interface NumberOfFundHouses {
  fundHouseNumber: number
}

export interface Account {
  name: string
  fundhouse: string | null
  currency: string
  company: string
  risk: string
  updateDatetime: Date
}

interface AccountList {
  accountList: Account[]
}

interface AccountUrl {
  url: string
}

interface AccountInfo {
  company: string
  fundHouseShort: string | null
  fundHouseLong: string | null
  name: string
  inceptionDate: Date | null
  currency: string
  risk: string
  payout: string | null
  managementFee: string | null
  custodianFee: string | null
  updateDatetime: Date
}

export const useStatisticsStore = defineStore('statistics', () => {
  const config = useRuntimeConfig()
  const Route = '/accountDesc'
  const BaseUrl = config.public.apiUrl + Route
  const GetNumberOfCompanies = '/NumberOfCompanies'
  const GetNumberOfAccounts = '/NumberOfAccounts'
  const GetNumberOfFundHouses = '/NumberOfFundHouses'

  const statisticsFetched = ref(false)
  const numberOfCompanies = ref({} as NumberOfCompanies)
  const numberOfAccounts = ref({} as NumberOfAccounts)
  const numberOfFundHouses = ref({} as NumberOfFundHouses)

  async function fetchNumberOfCompanies() {
    const data = await $fetch(GetNumberOfCompanies, {
      baseURL: BaseUrl,
      method: 'GET'
    })
    if (!isNumberOfCompanies(data)) {
      throw new Error('[GetNumberOfCompanies] Invalid response data')
    }
    numberOfCompanies.value = data as NumberOfCompanies
  }

  async function fetchNumberOfAccounts() {
    const data = await $fetch(GetNumberOfAccounts, {
      baseURL: BaseUrl,
      method: 'GET'
    })
    if (!isNumberOfAccounts(data)) {
      throw new Error('[GetNumberOfAccounts] Invalid response data')
    }
    numberOfAccounts.value = data as NumberOfAccounts
  }

  async function fetchNumberOfFundHouses() {
    const data = await $fetch(GetNumberOfFundHouses, {
      baseURL: BaseUrl,
      method: 'GET'
    })
    if (!isNumberOfFundHouses(data)) {
      throw new Error('[GetNumberOfFundHouses] Invalid response data')
    }
    numberOfFundHouses.value = data as NumberOfFundHouses
  }

  async function fetchStatistics() {
    await Promise.all([
      fetchNumberOfCompanies(),
      fetchNumberOfAccounts(),
      fetchNumberOfFundHouses()
    ])

    statisticsFetched.value = true
  }

  return {
    statisticsFetched,
    numberOfCompanies,
    numberOfAccounts,
    numberOfFundHouses,
    fetchStatistics
  }
})

export const useAccountStore = defineStore('accountDesc', () => {
  const config = useRuntimeConfig()
  const Route = '/accountDesc'
  const BaseUrl = config.public.apiUrl + Route
  const GetAccountList = '/AccountList'
  const GetAccountUrl = '/AccountUrl'
  const GetAccountInfo = '/AccountInfoByUrl'

  const accountListFetched = ref(false)
  const accountList = ref({} as AccountList)
  const accountUrlFetched = ref(false)
  const accountUrl = ref({} as AccountUrl)
  const accountInfoFetched = ref(false)
  const accountInfo = ref({} as AccountInfo)

  async function fetchAccountList() {
    const data = await $fetch(GetAccountList, {
      baseURL: BaseUrl,
      method: 'GET'
    })
    if (!isAccountList(data)) {
      throw new Error('[GetAccountList] Invalid response data')
    }
    accountList.value = data as AccountList
    accountListFetched.value = true
  }

  async function fetchAccountUrl(
    name: string,
    fundhouse: string | null,
    currency: string,
    company: string,
    risk: string,
    updateDatetime: Date
  ) {
    const data = await $fetch(GetAccountUrl, {
      baseURL: BaseUrl,
      method: 'GET',
      query: { name, fundhouse, currency, company, risk, updateDatetime }
    })
    accountUrl.value = data as AccountUrl
    accountUrlFetched.value = true
  }

  async function fetchAccountInfo(url: string) {
    const data = await $fetch(GetAccountInfo, {
      baseURL: BaseUrl,
      method: 'GET',
      query: { url }
    })
    if (!isAccountInfo(data)) {
      throw new Error('[GetAccountInfo] Invalid response data')
    }
    accountInfo.value = data as AccountInfo
    accountInfoFetched.value = true
  }

  return {
    accountList,
    accountListFetched,
    fetchAccountList,
    accountUrl,
    accountUrlFetched,
    fetchAccountUrl,
    accountInfo,
    accountInfoFetched,
    fetchAccountInfo
  }
})

function isNumberOfCompanies(obj: any): obj is NumberOfCompanies {
  return typeof obj.companyNumber === 'number'
}

function isNumberOfAccounts(obj: any): obj is NumberOfAccounts {
  return typeof obj.accountNumber === 'number'
}

function isNumberOfFundHouses(obj: any): obj is NumberOfFundHouses {
  return typeof obj.fundHouseNumber === 'number'
}

function isAccountList(obj: any): obj is AccountList {
  return Array.isArray(obj.accountList)
}

function isAccountInfo(obj: any): obj is AccountInfo {
  return (
    typeof obj.company === 'string' &&
    typeof obj.fundHouseShort === 'string' &&
    typeof obj.fundHouseLong === 'string' &&
    typeof obj.name === 'string' &&
    // typeof obj.inceptionDate === 'string' &&
    typeof obj.currency === 'string' &&
    typeof obj.risk === 'string' &&
    // typeof obj.managementFee === 'number' &&
    // typeof obj.custodianFee === 'number' &&
    // typeof obj.fundHouse === 'string' &&
    // typeof obj.fundManager === 'string' &&
    // typeof obj.payout === 'string' &&
    // typeof obj.aum === 'number' &&
    typeof obj.updateDatetime === 'string'
  )
}
